exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-carousel-js": () => import("./../../../src/pages/carousel.js" /* webpackChunkName: "component---src-pages-carousel-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-contenido-acerca-de-js": () => import("./../../../src/pages/contenido/Acerca-de.js" /* webpackChunkName: "component---src-pages-contenido-acerca-de-js" */),
  "component---src-pages-contenido-preguntas-frecuentes-js": () => import("./../../../src/pages/contenido/Preguntas-Frecuentes.js" /* webpackChunkName: "component---src-pages-contenido-preguntas-frecuentes-js" */),
  "component---src-pages-contenido-sistema-de-premios-o-beneficios-js": () => import("./../../../src/pages/contenido/Sistema-de-premios-o-beneficios.js" /* webpackChunkName: "component---src-pages-contenido-sistema-de-premios-o-beneficios-js" */),
  "component---src-pages-contenido-venir-a-jugar-js": () => import("./../../../src/pages/contenido/venir-a-jugar.js" /* webpackChunkName: "component---src-pages-contenido-venir-a-jugar-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-metro-card-js": () => import("./../../../src/pages/metro-card.js" /* webpackChunkName: "component---src-pages-metro-card-js" */),
  "component---src-pages-nuevas-reglas-js": () => import("./../../../src/pages/nuevas-reglas.js" /* webpackChunkName: "component---src-pages-nuevas-reglas-js" */),
  "component---src-pages-payment-mp-js": () => import("./../../../src/pages/payment/mp.js" /* webpackChunkName: "component---src-pages-payment-mp-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-shop-como-funciona-js": () => import("./../../../src/pages/shop/como-funciona.js" /* webpackChunkName: "component---src-pages-shop-como-funciona-js" */),
  "component---src-pages-shop-home-js": () => import("./../../../src/pages/shop/home.js" /* webpackChunkName: "component---src-pages-shop-home-js" */),
  "component---src-pages-shop-local-js": () => import("./../../../src/pages/shop-local.js" /* webpackChunkName: "component---src-pages-shop-local-js" */),
  "component---src-pages-tools-game-grid-js": () => import("./../../../src/pages/tools/gameGrid.js" /* webpackChunkName: "component---src-pages-tools-game-grid-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-authors-js": () => import("./../../../src/templates/authors.js" /* webpackChunkName: "component---src-templates-authors-js" */),
  "component---src-templates-game-js": () => import("./../../../src/templates/game.js" /* webpackChunkName: "component---src-templates-game-js" */),
  "component---src-templates-games-comming-soon-js": () => import("./../../../src/templates/gamesCommingSoon.js" /* webpackChunkName: "component---src-templates-games-comming-soon-js" */),
  "component---src-templates-games-for-sale-js": () => import("./../../../src/templates/gamesForSale.js" /* webpackChunkName: "component---src-templates-games-for-sale-js" */),
  "component---src-templates-games-selection-js": () => import("./../../../src/templates/gamesSelection.js" /* webpackChunkName: "component---src-templates-games-selection-js" */),
  "component---src-templates-paginated-games-js": () => import("./../../../src/templates/paginatedGames.js" /* webpackChunkName: "component---src-templates-paginated-games-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

