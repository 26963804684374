import { render } from 'react-dom'
// import Firebase from './src/components/Firebase'
import "./src/styles/global.css"

export const onServiceWorkerUpdateFound = () => {
  const answer = window.confirm(
    `La página ha sido actualizada. ` +
      `Actualizas a la última versión?`
  )
  if (answer === true) {
    window.location.reload()
  }
}

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`);
  }
}

export const onRouteUpdate = () => {
  // Don't track while developing.
  if (process.env.NODE_ENV === `production` && typeof fbq === `function`) {
    // eslint-disable-next-line no-undef
    fbq("track", "ViewContent");
  }
};

// export const wrapRootElement = ({ element, props }) => (
//   <Firebase
//     {...props}
//   >
//     {element}
//   </Firebase>
// );

