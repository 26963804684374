import { red } from '@material-ui/core/colors';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

// A custom theme for this app
const theme = responsiveFontSizes(createMuiTheme({
  transitions: {
    // So we have `transition: none;` everywhere
    create: () => 'none',
  },
  typography: {
    fontFamily: 'Rajdhani, sans-serif',
  },
  palette: {  
    primary: {
      main: '#ff5722',
      contrastText: '#fafafa',
    },
    secondary: {
      main: '#00B8D4',
      contrastText: '#fafafa',
    },
    grey: {
      main: '#6f6f6f',
      contrastText: '#fafafa',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
    highlight: {
      main: '#ffcc33'
    }
  },
}));

export default theme;
